import styled from 'styled-components';

const LinkButton = styled.a`
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  background-color: #ff9331;
  font-family: 'RobotoBold';
  padding: 15px 32px !important;
  font-size: 21px;
  line-height: 1;
  border-radius: 6px;
  color: #FFFFFF;

  &:hover {
    background-color: #ee8522;
    outline: none;
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
  }
`;

export default LinkButton;
